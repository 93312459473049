import {SenecaResponse} from "atfcore-commonclasses";

/**
 * Direttiva che costruisce la parte di edit delle comunicazioni del corso
 */

interface IEditCommunicationScope {
    course: any;
    courseDate: any;
    isDoingSomething: boolean;
    templates: any;
    isOnlineCourse: boolean;
    bgAcademyApplicationData: any;
    previewTemplateMail: Function;
    genericBlockUI: any;
    reminderDate: any;
    openReminderDate: Function;
}

class EditCommunicationController {
    static $inject = ["$scope", "$rootScope", "$translate", "toaster", "BgacademyApplicationData", "$mdDialog", "UtilService", "blockUI" ];

    constructor(private $scope: IEditCommunicationScope,
        private $rootScope: angular.IRootScopeService,
        private $translate: angular.translate.ITranslateService,
        private toaster: any,
        private BgacademyApplicationData: any,
        private $mdDialog: ng.material.IDialogService,
        private UtilService: any,
        private blockUI: any
    ) {
        $scope.bgAcademyApplicationData = BgacademyApplicationData;
        $scope.isOnlineCourse = $scope.course.courseType.name == BgacademyApplicationData.constants.ONLINE ? true : false;
        $scope.genericBlockUI = this.blockUI.instances.get('genericBlockUI');
        $scope.previewTemplateMail = this.previewTemplateMail;

        $scope.reminderDate = {
            opened: false
        };

        $scope.openReminderDate = function () {
            $scope.reminderDate.opened = true;
        };
    }

    // pre una modale chiedendo conferma per l'inivio della mail con l'anteprima del template scelto
    previewTemplateMail = (event: any, templateType: any) => {
        this.$mdDialog.show({
            controller: this.PreviewTemplateMailController,
            templateUrl: 'previewTemplateMail.html',
            parent: angular.element(document.body),
            targetEvent: event,
            locals: {
                templateType: templateType
            },
            clickOutsideToClose: true
        })
            .then(() => {
                // In caso di dismiss non faccio niente
            });
    }

    // Controller della modale che chiede la conferma per l'inivio della mail con l'anteprima del template scelto
    PreviewTemplateMailController = ($scope: any, $mdDialog: any, templateType: any) => {
        // Innanzitutto recupero l'intero oggetto del template
        let templateObj: any = null;
        if (templateType == this.$scope.bgAcademyApplicationData.constants.COURSE_USER_INVITE_MAIL) {
            for (let i = 0; i < this.$scope.templates.mail.length; i++) {
                if (this.$scope.templates.mail[i].textTemplateId == this.$scope.templates.inviteTemplateId) {
                    templateObj = this.$scope.templates.mail[i];
                    break;
                }
            }
        } else if (templateType == this.$scope.bgAcademyApplicationData.constants.CONFIRM_SUBSCRIPTION_MAIL) {
            for (let i = 0; i < this.$scope.templates.mail.length; i++) {
                if (this.$scope.templates.mail[i].textTemplateId == this.$scope.templates.confirmTemplateId) {
                    templateObj = this.$scope.templates.mail[i];
                    break;
                }
            }
        } else if (templateType == this.$scope.bgAcademyApplicationData.constants.REFUSE_SUBSCRIPTION_MAIL) {
            for (let i = 0; i < this.$scope.templates.mail.length; i++) {
                if (this.$scope.templates.mail[i].textTemplateId == this.$scope.templates.refuseTemplateId) {
                    templateObj = this.$scope.templates.mail[i];
                    break;
                }
            }
        } else if (templateType == this.$scope.bgAcademyApplicationData.constants.INVITE_SOLICIT_MAIL) {
            for (let i = 0; i < this.$scope.templates.mail.length; i++) {
                if (this.$scope.templates.mail[i].textTemplateId == this.$scope.templates.solicitTemplateId) {
                    templateObj = this.$scope.templates.mail[i];
                    break;
                }
            }
        } else if (templateType == this.$scope.bgAcademyApplicationData.constants.REMINDER_MAIL) {
            for (let i = 0; i < this.$scope.templates.mail.length; i++) {
                if (this.$scope.templates.mail[i].textTemplateId == this.$scope.templates.reminderTemplateId) {
                    templateObj = this.$scope.templates.mail[i];
                    break;
                }
            }
        }

        // Chiude la modale annullando l'operazione
        $scope.cancel = () => {
            $mdDialog.cancel();
        };

        // Confermo l'operazione mandando la mail di test
        $scope.confirm = () => {
            // Chiudo la modale
            $mdDialog.hide();

            // Attivo il block-UI
            this.$scope.genericBlockUI.start();

            // Disabilito i pulsanti
            this.$scope.isDoingSomething = true;

            // Aggiunge gli utenti al gruppo selezionato
            this.UtilService.sendPreviewTemplateMail.query({
                template: templateObj,
                courseDateId: this.$scope.courseDate.courseDateId,
                courseId: this.$scope.courseDate.courseId
            }).$promise
                .then((data: SenecaResponse<any>) => {
                    if (data.error) {
                        // Dati non validi, quindi alzo l'errore
                        this.toaster.pop("error", this.$translate.instant('error.generic.TITLE'), this.$translate.instant('error.generic.MESSAGE'));

                        // Disattivo il block-UI
                        this.$scope.genericBlockUI.stop();
                    } else {
                        // Avverto l'utente che l'operazione è andata a buon fine
                        this.toaster.pop("success", this.$translate.instant('generic.OPERATION_PERFORMED'), this.$translate.instant('templateMailList.TEMPLATE_SENT'));

                        // Disattivo il block-UI
                        this.$scope.genericBlockUI.stop();
                    }

                    // Attivo i pulsanti
                    this.$scope.isDoingSomething = false;
                })
                .catch((error: any) => {
                    // Disattivo il block-UI
                    this.$scope.genericBlockUI.stop();
                    // Attivo i pulsanti
                    this.$scope.isDoingSomething = false;
                    // Non mostro la modale di errore se ho cancellato volutamente la richiesta                            
                    if (!error || error.config.timeout.$$state.status !== 1) {
                        let errors: Array<any> = [];
                        errors.push({ severity: "danger", message: this.$translate.instant("error.generic.UNKNOWN_ERROR") });
                        this.$rootScope.$emit("showApplicationModalErrors", errors);
                    }
                });
        };
    }
}

angular.module("app").directive("courseEditCommunication", () => {
    return {
        templateUrl: "app/shared/courseAdmin/editCommunication.html",
        scope: {
            course: "<",
            courseDate: "<",
            templates: "=",
            isDoingSomething: "=?"
        },
        controller: EditCommunicationController
    };
});